import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import Provider from 'components/Provider'
import { StaticImage } from "gatsby-plugin-image"
import Layout from "layouts/layout";
import SEO from 'components/seo';
import Button, { ButtonProps } from 'components/Base/Button'
import Header from 'components/Topic/Header';
import Footer from 'components/Topic/Footer';
import {
  Container
} from './index.module.less';

import iconSemicircleRight from 'assets/images/topic/ds2021Integral/icon-semicircle-right.png';
import iconSemicircleLeft from 'assets/images/topic/ds2021Integral/icon-semicircle-left.png';
import iconStar from 'assets/images/topic/ds2021Bug/icon-star.svg';

const Ds2021Integral: React.FC = () => {
  return (
    <Layout>
      <div className={Container}>
        <SEO 
          title="神策数据｜2021积分有礼｜神策数据驱动大会"
          description="第六届神策数据驱动大会的积分有礼活动，参与活动获取积分，积分榜前13名皆有好礼相送！"
          keywords="积分有礼,神策数据积分有礼，2021数据驱动大会,神策数据驱动大会"
          saTitle="首页-2021数据驱动大会-积分有礼"
        />
        <Header />
        <main>
          <Ds2021IntegralBanner />
          <Ds2021IntegralPanel title="活动介绍" className="activity-introduction">
            <img src={iconStar} alt="" className="icon-star hidden-mb" />
            <img src={iconSemicircleRight} alt="" className="icon-semicircle-right hidden-mb" />
            <img src={iconSemicircleLeft} alt="" className="icon-semicircle-left hidden-mb" />
            <Ds2021IntegralCard title="活动规则" type="rule" className="rule-wrap">
              <ul>
                <li>- 参与积分项目，符合条件的用户即可获取积分</li>
                <li>- 每个用户可以参与多个项目，积分在活动期间可累计</li>
                <li>- 若对活动规则等方面有任何问题，请邮件联系 mkt@sensorsdata.cn</li>
              </ul>
            </Ds2021IntegralCard>
            <Ds2021IntegralCard title="活动日程" type="schedule" className="schedule-wrap">
              <h4>- 积分获取 -</h4>
              <p>2021 神策数据驱动大会期间<br />（10 月 11 日- 12 月下旬）</p>
              <h4>- 结果公布 -</h4>
              <p>2021 年 12 月底</p>
            </Ds2021IntegralCard>
            <Ds2021IntegralCard title="积分榜" type="standings" className="standings-wrap">
              <p className="standings-content">积分榜采取周更机制<br />于 2021 年 10 月 18 日起更新，<br />于 2021 年 12 月底公布终榜</p>
              <div className="qrcode">
                <StaticImage src="../../../assets/images/topic/ds2021Integral/qrcode-jifenbang.png" alt="" />
                <p>微信扫码查看积分榜</p>
              </div>
            </Ds2021IntegralCard>
          </Ds2021IntegralPanel>
          <Ds2021IntegralPanel title="活动详情" className="activity-detail-wrap">
            <img src={iconStar} alt="" className="icon-star hidden-mb" />
            <Ds2021IntegralProjectItem 
              title="积分项目 1：话题互动" 
              theme="topic"
              stepList={[
                {
                  name: 'Step 1', 
                  title: '微信扫码进入话题互动专区',
                  staticImg: <StaticImage src="../../../assets/images/topic/ds2021Integral/qrcode-huati.png" alt="" className="qrcode-huati" />,
                  stepInfo: '',
                  content: <></>
                },
                {
                  name: 'Step 2', 
                  title: '选择感兴趣的话题畅所欲言',
                  stepInfo: <>#新认知 新力量#<br />#聊聊我和神策驱动大会的故事#<br />#期待 2021 数据驱动大会#<br />……</>,
                  content: <></>
                },
                {
                  name: 'Step 3', 
                  title: '将留言分享给好友点赞，冲击点赞榜',
                  stepInfo: <>每个话题的点赞榜 TOP 3 有额外积分哦</>,
                  content: <></>
                }
              ]}
              awardList={[
                {
                  title: '基础奖励：100 积分',
                  desc: <>发表话题留言即可获取<br />参与多个话题，基础奖励可重复发放</>
                },
                {
                  title: '额外奖励：300-500 积分',
                  desc: <>每个话题点赞榜 Top 3 分别获得 500、400、300 积分<br />获得多个话题点赞榜 Top 3，额外奖励可重复发放</>
                }
              ]} />
            <Ds2021IntegralProjectItem 
              title="积分项目 2：全民找“BUG” 2.0" 
              theme="bug"
              stepList={[
                {
                  name: 'Step 1', 
                  title: '使用神策产品，截图收集问题',
                  img: '',
                  stepInfo: <>老客户操作自有的神策产品<br />新客户免费体验产品 Demo</>,
                  button: { text: '点击参与', href: "/topic/ds2021Bug.html?tab=introduce#tab" },
                  content: <></>
                },
                {
                  name: 'Step 2', 
                  title: '提交“BUG”描述文档',
                  img: '',
                  stepInfo: <>- 产品功能问题<br />- 操作或展示的体验问题<br />- 产品新需求</>,
                  button: { text: '点击提交', href: "/topic/ds2021Bug.html?tab=form#tab" },
                  content: <></>
                },
                {
                  name: 'Step 3', 
                  title: '微信扫码进入积分榜，关注积分结果',
                  staticImg: <StaticImage src="../../../assets/images/topic/ds2021Integral/qrcode-jifenbang.png" alt="" />,
                  stepInfo: <>注意：倘若提交了文档没有看到积分，请不要着急，积分榜按周更新哦！</>,
                  content: <></>
                }
              ]}
              awardList={[
                {
                  title: '基础奖励：500 积分',
                  desc: <>提交 BUG 即可获得 500 积分<br />提交多个 BUG，基础奖励不重复发放</>
                },
                {
                  title: '额外奖励：500 积分',
                  desc: <>评选出的每个有效 BUG 额外获得 500 积分<br />提交多个有效 BUG，额外奖励可重复发放</>
                }
              ]} />
            <Ds2021IntegralProjectItem 
              title="积分项目 3：现场拍照区留念" 
              theme="camera"
              stepList={[
                {
                  name: 'Step 1', 
                  title: '来到驱动大会现场拍照区留念',
                  staticImg: <img src={require('assets/images/topic/ds2021Integral/camera-01.png').default} alt="" />,
                  content: <></>
                },
                {
                  name: 'Step 2', 
                  title: '登记信息，获取积分',
                  staticImg: <img src={require('assets/images/topic/ds2021Integral/camera-02.png').default} alt="" />,
                  stepInfo: <>在神策数据公众号，回复关键词「积分有礼」，即可登记信息，获取积分</>,
                  content: <></>
                }
              ]}
              awardList={[
                {
                  title: '基础奖励：100 积分',
                  desc: <>拍照区拍照即可获取，不重复发放</>
                },
                {
                  title: '额外奖励：500 积分',
                  desc: <>将照片分享至朋友圈即可获得，不重复发放</>
                }
              ]}>
              <img src={iconStar} alt="" className="icon-star hidden-mb" />
            </Ds2021IntegralProjectItem>
          </Ds2021IntegralPanel>
          <Ds2021IntegralPanel title="活动奖励" className="activity-reward-wrap">
            <img src={iconStar} alt="" className="icon-star hidden-mb" />
            <img src={iconSemicircleRight} alt="" className="icon-semicircle-right hidden-mb" />
            <img src={iconSemicircleLeft} alt="" className="icon-semicircle-left hidden-mb" />
            <ActivityRewardCard title="积分榜 Top 1" rewardName="极米投影仪" rewardPicture={<StaticImage src="../../../assets/images/topic/ds2021Integral/reward-01.png" alt="" className="reward-picture" />} rewardCount={1} />
            <ActivityRewardCard title="积分榜 Top 2-3" rewardName="AirPods Pro" rewardPicture={<StaticImage src="../../../assets/images/topic/ds2021Integral/reward-02.png" alt="" className="reward-picture" />} rewardCount={2} />
            <ActivityRewardCard title="积分榜 Top 4-6" rewardName="Keep 肌肉按摩器" rewardPicture={<StaticImage src="../../../assets/images/topic/ds2021Integral/reward-03.png" alt="" className="reward-picture" />} rewardCount={3} />
            <ActivityRewardCard title="积分榜 Top 7-13" rewardName="小米胶囊咖啡机" rewardPicture={<StaticImage src="../../../assets/images/topic/ds2021Integral/reward-04.png" alt="" className="reward-picture" />} rewardCount={7} />
          </Ds2021IntegralPanel>
        </main>
        <Footer />
      </div>
    </Layout>
  );
}

export default Ds2021Integral;

// 积分有礼-banner
const Ds2021IntegralBanner: React.FC = () => {
  return (
    <div className="ds2021-integral-banner">
      <article>
        <div className="ds2021-integral-banner--left">
          <h1>积分有礼</h1>
          <img 
            src={require('assets/images/topic/ds2021Integral/banner-subtitle.png').default}
            alt=""
            className="banner-subtitle" />
          <p>参与活动获取积分，积分榜 Top 13 皆有好礼相送！</p>
        </div>
        <div className="ds2021-integral-banner--right">
          <img src={require('assets/images/topic/ds2021Integral/banner-icon.png').default} alt="" className="banner-icon" />
        </div>
      </article>
    </div>
  );
}

interface Ds2021IntegralPanelProps {
  className?: string;
  title?: string;
}
// 积分有礼-panel
const Ds2021IntegralPanel: React.FC<Ds2021IntegralPanelProps> = (params) => {
  const {
    className,
    title,
    children
  } = params;
  return (
    <div className={classnames("ds2021-integral-panel", className)}>
      <div className="ds2021-integral-panel--header">
        <h2>{title}</h2>
        <StaticImage src="../../../assets/images/topic/ds2021Integral/icon-rectangle.png" alt="" className="line hidden-mb" />
        <StaticImage src="../../../assets/images/topic/ds2021Integral/icon-rectangle-mb.png" alt="" className="line hidden-pc" />
      </div>
      <div className="ds2021-integral-panel--body">
        <article>
          {children}
        </article>
      </div>
    </div>
  );
}


interface Ds2021IntegralCardProps {
  className?: string;
  title?: string;
  type?: string; // rule, schedule, standings
}
// 活动介绍卡片
const Ds2021IntegralCard: React.FC<Ds2021IntegralCardProps> = params => {
  const {
    className,
    title,
    type,
    children
  } = params;
  
  return (
    <div className={classnames('ds2021-integral-card', type, className)}>
      <h3 className="ds2021-integral-card--title">{title}</h3>
      <div className="ds2021-integral-card--content">
        {children}
      </div>
    </div>
  );
}


interface Ds2021IntegralProjectItemProps {
  className?: string;
  title?: string;
  theme?: string; // topic, bug, camera
  stepList?: StepCardProps[];
  awardList?: {title:string, desc: any}[];
}
// 活动详情单个项目面板
const Ds2021IntegralProjectItem: React.FC<Ds2021IntegralProjectItemProps> = params => {
  const {
    className,
    title,
    theme,
    stepList,
    awardList,
    children
  } = params;
  return (
    <div className={classnames("ds2021-integral-project-item", theme, className)}>
      <h3 className={classnames("ds2021-integral-project-item--title", theme)}>
        <span>{title}</span>
      </h3>
      <div className="ds2021-integral-project-item--body">
        <div className="ds2021-integral-project-item--body-left"></div>
        <div className="ds2021-integral-project-item--body-right">
          <h4>如何参与？</h4>
          <ul className="step-list">
            {
              stepList?.map((item, index) => 
                <StepCard 
                  key={index} 
                  name={item.name} 
                  title={item.title}
                  img={item.img}
                  staticImg={item.staticImg}
                  stepInfo={item.stepInfo}
                  button={item.button}>
                  {item.content}
                </StepCard>)
            }
          </ul>
          <h4>如何积分？</h4>
          <ul className="award-list">
            {awardList?.map((item, index) => <AwardCard key={index} title={item.title} desc={item.desc} />)}
          </ul>
        </div>
      </div>
      {children}
    </div>
  );
}

interface StepCardButton extends ButtonProps {
  text: string; 
  href?: string;
}
interface StepCardProps {
  name?: string;
  title?: string;
  img?: any;
  staticImg?: any;
  stepInfo?: any;
  button?: StepCardButton,
  content?: any;
}
// 步骤卡片
const StepCard: React.FC<StepCardProps> = params => {
  const {
    name,
    title,
    img,
    staticImg,
    stepInfo,
    button,
    children
  } = params;
  return (
    <li className="step-item">
      <div className="step-item-header">
        <p className="step-item-header--name">{name}</p>
        <p className="step-item-header--info">{title}</p>
      </div>
      <div className={classnames("step-item-content", {'has-button': button !== undefined})}>
        <div className="step-item-content--top">
          {staticImg ? staticImg : img && <img src="" alt="" />}
          {stepInfo && <p>{stepInfo}</p>}
        </div>
        {button && <div className="step-item-content--bottom">
          <Button btnType="blue" size="small" round target="_blank" href={button.href}>{button.text}</Button>
        </div>}
      </div>
    </li>
  );
}

interface AwardCardProps {
  title?: string;
  desc?: any;
}
// 奖励卡片
const AwardCard: React.FC<AwardCardProps> = params => {
  const {
    title,
    desc
  } = params;
  return (
    <li className="award-item">
      <h5>{title}</h5>
      <p>{desc}</p>
    </li>
  );
}

interface ActivityRewardCardProps {
  title?: string;
  rewardPicture?: any;
  rewardName?: string;
  rewardCount?: number;
}
const ActivityRewardCard: React.FC<ActivityRewardCardProps> = params => {
  const {
    title,
    rewardPicture,
    rewardName,
    rewardCount
  } = params;
  return (
    <div className="ds2021-activity-reward-card">
      <h3>{title}</h3>
      {rewardPicture}
      <p className="reward-name">{rewardName}</p>
      <p className="reward-count">{rewardCount} 名</p>
    </div>
  );
}
